/**
 * @author: Håvard Melvin Asperheim Hjelvik
 * @date: 03/11/2017
 */

import { get } from 'axios';
import { parseUrl, RunScript } from 'Core';
import { addEvent } from 'DartListener';

const TRANSLATION_STRATEGY_LEGACY = "legacy";
const TRANSLATION_STRATEGY_LOCALAZY = "localazy";
const TRANSLATION_STRATEGY_LOCALAZY_CDN = "localazy_cdn"

class DartTranslate {
    static async init() {
        DartTranslate.activeLanguage = 'no';
        try {
            DartTranslate.lang = await DartTranslate.connectLang();
            addEvent('.evtChangeLanguage', 'change', DartTranslate.handleEvtChangeLanguage);
            return true;
        } catch(error) {
            console.error(error);
            return false;
        }
    } // End init

    static async connectLang() {
        DartTranslate.lang = {};

        try {
            let response = await fetch(parseUrl('api/getlanguage'), {
                credentials: 'same-origin'
            });
            let json = await response.json();
            DartTranslate.currentLanguage(json.payload.lang);
            DartTranslate.currentStrategy(json?.payload?.strategy || null);
            return json.payload.translation;
        } catch(error) {
            console.error(error);
            return false;
        }
    } // End getLang

    static currentLanguage(set = null) {
        if (set !== null) {
            DartTranslate.activeLanguage = set;
        }
        return DartTranslate.activeLanguage;
    } // End currentLanguage

    static currentStrategy(set = null) {
        if (set !== null) {
            DartTranslate.activeStrategy = set;
        }
        return DartTranslate.activeStrategy;
    } // End currentStrategy

    static translateLegacy(key, num = null, onlyWord = false) {
        let lang = DartTranslate.lang;

        key = key.toUpperCase().replace('/(?(?<=[a-z])(?=[A-Z]) | (?<=[A-Z])(?=[A-Z][a-z])/x/g', '_');

        if (lang.hasOwnProperty(key)) {

            const langObj = lang[key];

            let currentStr = '';
            if (langObj instanceof Array) {
                if (num === null) {
                    currentStr = langObj[0];
                } else {
                    currentStr = langObj[0];
                    if (num === 0 || num > 1) {
                        currentStr = langObj[1];
                    }
                }
            } else {
                currentStr = lang[key];
            }

            let res = '';
            if (num !== null) {
                if (currentStr.includes('[:x]')) {
                    if (onlyWord) {
                        num = '';
                    }
                    res = currentStr.replace('[:x]', num);
                } else {
                    if (onlyWord) {
                        res = currentStr;
                    } else {
                        res = `${num} ${currentStr}`;
                    }
                }
            } else {
                res = currentStr;
            }

            return res;
        }

        return `[-${key}-]`;
    } // End translateLegacy

    static translateLocalazy(key, plural = null, hideNumber = false) {
        let lang = DartTranslate.lang;

        key = key.toLowerCase().replace('/(?(?<=[a-z])(?=[A-Z]) | (?<=[A-Z])(?=[A-Z][a-z])/x/g', '_');

        if (lang.hasOwnProperty(key)) {

            const langObj = lang[key];

            let currentStr = key;
            if (langObj instanceof Array || langObj instanceof Object) {
                if (plural === null) {
                    currentStr = langObj?.one || langObj[0];
                } else {
                    currentStr = langObj?.one || langObj[0];
                    if (plural === 0 || plural > 1 || plural < -1) {
                        currentStr = langObj?.other || langObj[1];
                    }
                }
            } else {
                currentStr = langObj;
            }

            let res = '';
            if (plural !== null) {
                if (currentStr.includes('%d')) {
                    if (hideNumber) {
                        plural = '';
                        res = currentStr.replace('%d ', plural);
                    } else {
                        res = currentStr.replace('%d', plural);
                    }
                } else {
                    if (hideNumber) {
                        res = currentStr;
                    } else {
                        res = `${plural} ${currentStr}`;
                    }
                }
            } else {
                res = currentStr;
            }

            return res;
        }

        return key;
    } // end translateLocalazy

    static translate(key, plural = null, hideNumber = false) {
        if (DartTranslate.currentStrategy() == TRANSLATION_STRATEGY_LOCALAZY || DartTranslate.currentStrategy() == TRANSLATION_STRATEGY_LOCALAZY_CDN) {
            return DartTranslate.translateLocalazy(key, plural, hideNumber);
        }

        return DartTranslate.translateLegacy(key, plural, hideNumber)
    } // End translate

    static handleEvtChangeLanguage(e) {
        e.preventDefault();

        const language = $(this).val();

        RunScript('api/setlanguage', { language }, '', () => {
            location.reload();
        });
    } // End handleEvtChangeLanguage

} // End DartTranslate

export default DartTranslate.translate;
export const init = DartTranslate.init;
export const connect = DartTranslate.connectLang;
export const currentLanguage = DartTranslate.currentLanguage;
