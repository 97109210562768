/**
 * @author: Håvard Melvin Asperheim Hjelvik
 * @date: 07/11/2017
 */

import { init as InitTranslate } from 'DartTranslate';
import { DartGet } from 'Core';

import {each} from 'lodash';

export default class Dart {

    static init = () => {
        Dart.loggedInUser = false;

        Dart.getNecessary()
            .then(val => {
                Dart.isReady = true;
                Dart.handleComponents();
            })
            .catch(error => {
                console.error('Necessary scripts not loaded', error);
            });
    } // End init

    static handleComponents = () => {
        if (Dart.isReady) {
            each(Dart.components, component => {
                component();
            });
            Dart.components = [];
        }
    } // End handleReady

    static ready = (methods) => {
        if (!Array.isArray(methods)) {
            methods = [methods];
        }
        if (!Array.isArray(Dart.components)) {
            Dart.components = [];
        }
        Dart.components = [...Dart.components, ...methods];
        Dart.handleComponents();
    } // End ready

    static async getNecessary() {
        try {
            const necessary = await Promise.all([
                InitTranslate(),
                Dart.fetchLoggedInUser()
            ]);
            return await necessary;
        } catch(error) {
            console.error('Necessary scripts not loaded', error);
            return false;
        }
    } // End getNecessary

    static LoggedInUser() {
        if (Dart.loggedInUser) {
            return Dart.loggedInUser;
        }
        return false;
    } // End LoggedInUser

    static async fetchLoggedInUser() {
        try {
            const user = await DartGet('api/systemusers/loggedin');

            if (user.data.payload.id > 0) {
                Dart.loggedInUser = user.data.payload;
                return await Dart.loggedInUser;
            }

            return false;
        } catch (e) {
            // No user signed in, ignore
            return false;
        }
    }

} // End Dart

export const ready = Dart.ready;
export const LoggedInUser = Dart.LoggedInUser;