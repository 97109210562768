/**
 * @author: Håvard Melvin Asperheim Hjelvik
 * @date: 02/10/2017
 */

import './Js/Polyfills';
import axios from 'axios';
import qs from 'qs';
import { isString } from 'lodash';
import $ from 'jquery';

import DartRouter, {DART_ROUTE_DISPATCHED, DART_ROUTE_FULFILLED, DART_ROUTE_FAILED} from 'DartRouter';
import DartListener from 'DartListener';

import Dart from 'Dart';
import Translate from 'DartTranslate';

class Core {

    systemTitle = null;
    pageTitle = null;

    static async init() {
        this.requests = {};

        DartRouter.init();
        DartListener.init();

        try {
            const [DartTabs, DartLiveRun, DartBSDialog] = await Promise.all([
                import('./Js/DartTabs'),
                import('./Js/DartLiveRun'),
                import('./Js/DartBSDialog')
            ]);

            DartTabs.default.init();
            DartLiveRun.default.init();
            DartBSDialog.default.init();

            Dart.init();
        } catch(error) {
            console.error('Other core scripts failed to load', error);
        }
    } // End init

    static RunScript(name, postData, updateArea, callback = null, requestId = null, routing = false, waitForContent = false) {

        if (updateArea === 'mainArea' && waitForContent === false) {
            $('html, body').animate({scrollTop: 0}, 'slow');
        }

        let $updateArea = null;
        if (updateArea !== undefined && updateArea.length > 0) {
            if (typeof updateArea === 'string') {
                $updateArea = $("#" + updateArea);
            } else {
                $updateArea = updateArea;
            }
        }

        let $loadingWheel = null;
        if (Dart.isReady && Dart.isReady === true) {
            $loadingWheel = $('<div class="loadWrappers"><div class="loadingWheelWrappers"><i>' + Translate('LOADING') + '...</i></div></div>');
        } else {
            $loadingWheel = $('<div class="loadWrappers"><div class="loadingWheelWrappers"><i>Loading...</i></div></div>');
        }

        if ($updateArea !== null && $updateArea !== undefined && waitForContent === false) {
            $updateArea.html($loadingWheel);
        }


        if(postData === null || postData === undefined){
            postData = {};
        }

        postData.urlScript = name;
        if (typeof updateArea == 'string') {
            postData.updateArea = updateArea;
        }
        // postData.id = 1;


        /**
         * @date 22.01.2018
         *
         * Do not know why it used window.location.pathname. It should use name which is the correct url to parse...
         * If something fails, remove the comment and comment out line 84 instead. System probably then needs fixing a lot of other places.
         */
        // const urlObj = Core.parseUrlComprehensive(window.location.pathname);
        const urlObj = Core.parseUrlComprehensive(name);
        const url = Core.parseUrl(name);

        if (urlObj.data.numerics !== undefined || urlObj.data.numerics !== null) {
            postData.numerics = urlObj.data.numerics;
        }

        if (urlObj.data.id && (postData.id === undefined || postData.id === null)) {
            postData.id = urlObj.data.id;
        }

        if (urlObj.data.label) {
            postData.label = urlObj.data.label;
        }

        if (requestId === null) {
            requestId = url;
        }
        if (Core.requests[requestId] !== undefined && Core.requests[requestId] !== null) {
            Core.requests[requestId].cancel(`Cancelled by duplicate request to same requestId (${requestId})`);
            Core.requests[requestId] = null;
        }

        const axiosSource = axios.CancelToken.source();
        Core.requests[requestId] = axiosSource;

        axios({
            method: 'post',
            url: url,
            data: qs.stringify(postData),
            cancelToken: axiosSource.token
        })
            .then(response => {
                if (routing) {
                    Core.setSiteTitle(response.headers);
                    Core.validateAccess(response.headers);
                    DartRouter.triggerEvent(DART_ROUTE_FULFILLED);
                }
                if (callback !== null) {
                    callback(response.data, $updateArea);
                } else {
                    if ($updateArea !== null) {
                        $updateArea.html(response.data);
                    }
                }
                Core.requests[requestId] = null;
            })
            .catch(error => {
                if (axios.isCancel(error)) {
                    console.log(error.message)
                } else {
                    console.error('Something went wrong, please try again.\n\nIf problem continues, please contact site owner', error);
                }
                $loadingWheel[0].textContent = error.message;
            });

    } // End RunScript

    static parseUrl(url) {
        let href = "";
        const origin = window.location.origin;

        if (typeof url === 'string' && url.charAt(0) !== "/") {
            href = "/" + url;
        } else {
            href = url;
        }

        return origin + href;
    } // End parseUrl

    static parseUrlComprehensive(path) {
        if (typeof path === 'string' && path.charAt(0) === "/") {
            path = path.substring(1);
        }

        let url = "";
        let area = "mainArea";
        let pageIds = [];
        let labels = [];
        let data = {};

        let iteratorStart = 1;

        const pathParts = path.split("/");
        const pathLength = pathParts.length;

        if (pathParts[0] === "admin" || pathParts[0] === "smb" || pathParts[0] === "public") {
            area = "mainArea";
        } else {
            iteratorStart = 0;
        } // Sets load-area

        for (let i = iteratorStart; i < pathLength; i++) {

            const currentPathPart = pathParts[i];

            if (currentPathPart !== null || currentPathPart !== undefined) {

                // Builds URL
                if (currentPathPart.length > 0) {
                    url += "/" + pathParts[i];
                }

                // If part of path is integer, sends it as parameter aswell
                if (Core.isInteger(currentPathPart) && currentPathPart.length > 0) {
                    pageIds.push(currentPathPart);
                } else if (isString(currentPathPart)) {
                    labels.push(currentPathPart);
                }

            }

        } // End for-loop

        if (pageIds.length > 0 || labels.length > 0) {
            data = {
                numerics: pageIds,
                label: (labels.slice(-1)[0] || null),
                strings: labels
            };

            if (pageIds[0]) {
                data.id = pageIds[0];
            }
        } else {
            data = {};
        }

        const queryParts = path.split('?');
        let searchQuery = false;
        if (queryParts.length == 2) {
            searchQuery = qs.parse(queryParts[1]);
        }

        return {
            url: url,
            data: data,
            area: area,
            query: searchQuery
        };
    } // End sortUrl

    // Different from Number.isInteger in the way that it removes the integer wrapped in a string
    static isInteger(value) {
        const x = parseFloat(value);
        return !isNaN(value) && (x | 0) === x;
    } // End isInteger

    static guid() {
        let s = [];
        const hexDigits = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
        for (let i = 0; i < 6; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        return s.join("");
    } // End guid

    static setSiteTitle = (headers) => {
        let baseTitle = null;
        let pageTitle = null;
        if (headers['x-base-title'] && headers['x-base-title'].length > 0) {
            baseTitle = decodeURIComponent(headers['x-base-title']);
        }
        if (headers['x-page-title'] && headers['x-page-title'].length > 0) {
            Core.pageTitle = pageTitle = decodeURIComponent(headers['x-page-title']);
        } else {
            Core.pageTitle = null;
        }

        let title = null;
        if (typeof baseTitle === 'string') {
            title = baseTitle;
            Core.systemTitle = baseTitle;
        }

        if (typeof pageTitle === 'string') {
            title = `${pageTitle} | ${baseTitle}`;
        }

        if (typeof title === 'string') {
            // titleElement.text = title;
            document.title = title;
        } else if (typeof Core.systemTitle === 'string') {
            // titleElement.text = Core.systemTitle;
            document.title = Core.systemTitle;
        } // TODO: if none, get title from API. (Create API for js state. Title, language etc...)

    } // End setSiteTitle

    static DartPut = async (url, data, customObject = {}) => {
        const defaultObject = {
            method: 'put',
            url: Core.parseUrl(url),
            data: data,
            withCredentials: true
        };

        return await axios({...defaultObject, ...customObject});
    } // End DartPut

    static DartGet = async (url, data, customObject = {}) => {
        const defaultObject = {
            method: 'get',
            url: Core.parseUrl(url),
            params: data,
            withCredentials: true
        };

        return await axios({...defaultObject, ...customObject});
    } // End DartPut

    static DartDelete = async (url, data, customObject = {}) => {
        const defaultObject = {
            method: 'delete',
            url: Core.parseUrl(url),
            params: data,
            withCredentials: true
        };

        return await axios({...defaultObject, ...customObject});
    } // End DartPut

    static DartPost = async (url, data, params = {}, customObject = {}) => {
        const defaultObject = {
            method: 'post',
            url: Core.parseUrl(url),
            data: data,
            params: params,
            withCredentials: true
        };

        return await axios({...defaultObject, ...customObject});
    }; // End DartPut

    static validateAccess = (headers) => {
        let loggedInStatus = false;
        let accessDenied = false;

        if (headers['x-logged-in'] && headers['x-logged-in'].length > 0) {
            loggedInStatus = (decodeURIComponent(headers['x-logged-in']) == 'true');
        }

        if (headers['x-access-denied'] && headers['x-access-denied'].length > 0) {
            accessDenied = (decodeURIComponent(headers['x-access-denied']) == 'true');
        }

        if (loggedInStatus === false && accessDenied === true && window.location.path) {
            window.location.replace(`/?path=${window.location.path}`);
        }

    } // End setSiteTitle
} // End Core

Core.init();


export default Core;
export const RunScript = Core.RunScript;
export const DartPut = Core.DartPut;
export const DartGet = Core.DartGet;
export const DartPost = Core.DartPost;
export const DartDelete = Core.DartDelete;
export const parseUrl = Core.parseUrl;
export const parseUrlComprehensive = Core.parseUrlComprehensive;
export const isInteger = Core.isInteger;
export const guid = Core.guid;

// module.exports for use in providePlugin in Webpack
// module.exports = Core;

// Global adds to window in browser
// global.RunScript = Core.RunScript;
// global.parseUrl = Core.parseUrl;
// global.sortUrl = global.parseUrlComprehensive = Core.parseUrlComprehensive;