/**
 * @author: Håvard Melvin Asperheim Hjelvik
 * @date: 04/10/2017
 */

import $ from 'jquery';
import { createBrowserHistory } from 'history'
import Core from 'Core';

import { addEvent } from "DartListener";
import Translate from 'DartTranslate';
import Dart from "Dart";

import qs from 'qs';

export const DART_ROUTE_INITIALIZED = 'dart:route:initialized';
export const DART_ROUTE_DISPATCHED = 'dart:route:dispatched';
export const DART_ROUTE_FULFILLED = 'dart:route:fulfilled';
export const DART_ROUTE_FAILED = 'dart:route:failed';

export default class DartRouter {
    static init() {
        Dart.ready(DartRouter.initHelper);

        DartRouter.history = createBrowserHistory({
            getUserConfirmation: (message, callback) => callback(window.confirm(message))
        });
        DartRouter.historyListener = DartRouter.history.listen(DartRouter.handleHistory);

        addEvent('a.evtLink', 'click', DartRouter.handleEvtLink);
        addEvent('.evtFilter', 'click', DartRouter.handleEvtFilter);
        addEvent('a.evtLinkRow', 'click', DartRouter.handleEvtLinkRow);
    } // End init

    static initHelper() {
        const $siteTemplateInput = $('#siteTemplate:input');
        const $sitePathInput = $('#sitePath:input');
        const $siteRenderArea = $('#siteRenderArea:input');

        if ($siteTemplateInput.length > 0) {
            DartRouter.siteTemplate = $siteTemplateInput.val();
        }

        if ($sitePathInput.length > 0) {
            DartRouter.sitePath = $sitePathInput.val();
        }

        if ($siteRenderArea.length > 0) {
            DartRouter.siteRenderArea = $siteRenderArea.val();
        }

        const $siteTabArea = $('#siteTabArea:input');
        const $siteTabUrl = $('#siteTabeUrl:input');

        if ($siteTabArea.length > 0 && $siteTabUrl.length > 0) {
            DartRouter.siteTab = {
                'area': $siteTabArea.val(),
                'url': $siteTabUrl.val()
            };
        }
    } // End initHelper

    static handleEvtFilter(e) {
        if (e.metaKey || e.ctrlKey) {
            return;
        }
        e.preventDefault();

        if (!DartRouter.triggerEvent(DART_ROUTE_INITIALIZED)) {
            return;
        }

        const $_this = $(e.target);
        const filter = $_this.data('filter');
        const value = $_this.data('value');

        const location = DartRouter.history.location;
        let query = qs.parse(location.search.replace('?', ''));

        if (value.length > 0) {
            query = {...query, [filter]: value};
        } else {
            delete query[filter];
            query = {...query};
        }

        if (DartRouter.history) {
            DartRouter.history.push({
                pathname: location.pathname,
                search: qs.stringify(query),
                state: location.state
            });
        } else {
            return true;
        }

    } // End handleEvtFilter

    static handleEvtLink(e) {
        if (e.metaKey || e.ctrlKey) {
            return;
        }
        
        e.preventDefault();

        if (!DartRouter.triggerEvent(DART_ROUTE_INITIALIZED)) {
            return;
        }

        if (!($(e.target).closest(".noLink").length > 0)) {
            const _this = this;
            const $_this = $(_this);
            let href = $_this.attr("href");
            const target = $_this.attr("target");

            // TODO: check http, add target _blank
            if (typeof target !== typeof undefined && target === '_blank') {
                window.open(href);
                return true;
            }

            if (href === null || href === undefined) {
                href = "";
            }

            if (($_this.data("area") || href.length > 0)) {
                const area = $_this.data("area");
                const preserveQuery = $_this.data('preservequery');
                const urlObj = Core.parseUrlComprehensive(href);

                const splittedHref = href.split('?');
                if (splittedHref.length == 2) {
                    href = splittedHref[0]
                }

                if (DartRouter.history) {
                    const params = {
                        pathname: href,
                        state: {"url": urlObj.url, "area": area, "semantic": urlObj.url}
                    };

                    if (preserveQuery !== undefined) {
                        params.search = DartRouter.history.location.search;
                    } else {
                        if (urlObj.query) {
                            params.search = '?' + qs.stringify(urlObj.query, { encode: false })
                        }
                    }

                    DartRouter.history.push(params);
                } else {
                    return true;
                }

            }
        }
    } // End handleEvtLink

    static handleEvtLinkRow(e) {
        if (e.metaKey || e.ctrlKey) {
            return;
        }
        e.preventDefault();

        if (!DartRouter.triggerEvent(DART_ROUTE_INITIALIZED)) {
            return;
        }

        const $_this = $(this);
        // TODO: avvoid hardcoding semantickey
        let semanticKey = "admin";

        const linkID = $_this.parent().data("id");
        const tableParent = $_this.closest("table");

        let updateArea = tableParent.data("updatearea");
        const updateScript = tableParent.data("updatescript");
        const preserveQuery = tableParent.data("preservequery");

        if (updateArea.length === 0) {
            updateArea = "mainArea";
        }

        let url = updateScript + "/" + linkID;

        const urlObj = Core.parseUrlComprehensive(url);

        if (DartRouter.history) {
            const params = {
                pathname: "/" + semanticKey + urlObj.url,
                state: {"url": urlObj.url, "area": updateArea, "semantic": urlObj.url}
            };

            if (preserveQuery !== undefined) {
                params.search = DartRouter.history.location.search;
            }

            DartRouter.history.push(params);
        } else {
            window.location.href = "";
            return true;
        }
    } // End handleEvtLinkRow

    static handleHistory(location, action) {
        let url, area, tab;

        DartRouter.routeBlocker(false);

        if (DartRouter.prevPathname !== undefined) {
            let prevPathname = DartRouter.prevPathname;
            let prevHash = DartRouter.prevHash;
            let prevSearch = DartRouter.prevSearch;


            if ((location.pathname == prevPathname && location.search == prevSearch) || (prevHash != location.hash)) {
                return true;
            }
        }

        if (action == "REPLACE") {
            return true;
        }

        if (location.state) {
            const state = location.state;

            url = state.url;
            area = state.area;

            if (state.tab !== null && state.tab !== undefined) {
                tab = state.tab;
            }
        } else {
            if (DartRouter.siteTemplate && DartRouter.siteRenderArea && DartRouter.sitePath) {
                const siteTemplate = DartRouter.siteTemplate;
                const siteRenderArea = DartRouter.siteRenderArea;
                const sitePath = DartRouter.sitePath;

                if(sitePath.indexOf('/') === 0) {
                    url = sitePath.replace('/' . siteTemplate.toLowerCase(), '');
                } else {
                    url = sitePath.replace(siteTemplate.toLowerCase(), '');
                }

                area = siteRenderArea;

                /*
                if (DartRouter.siteTab) {
                    tab = DartRouter.siteTab;
                }
                */
            } else {
                return true;
            }
        }

        const urlObj = Core.parseUrlComprehensive(url);
        const search = qs.parse(location.search, { ignoreQueryPrefix: true } );


        if (tab !== null && tab !== undefined){
            const tabArea = document.getElementById(tab.area);

            let areaKey = false;
            if (tabArea) {
                areaKey = tabArea.dartAreaKey
            }

            const tabUrlObj = Core.parseUrlComprehensive(tab.url);

            if (tabArea && tab.areaKey == areaKey) {
                Core.RunScript(tabUrlObj.url, {...search, ...tabUrlObj.data}, tab.area, null, 'evtHandleHistory');
            } else {
                // const callback = (data, $area) => {
                //     $area.html(data);
                //     Core.RunScript(tabUrlObj.url, {...search, ...tabUrlObj.data}, tab.area);
                // };
                // Core.RunScript(urlObj.url, {...search, ...urlObj.data}, area, callback, 'evtClickRun');
                Core.RunScript(urlObj.url, {...search, ...urlObj.data}, area, null, 'evtClickRun');
            }
        } else{
            Core.RunScript(urlObj.url, {...search, ...urlObj.data}, area, null, 'evtHandleHistory', true);
        }
        DartRouter.triggerEvent();

        DartRouter.prevPathname = location.pathname;
        DartRouter.prevHash = location.hash;
        DartRouter.prevSaarch = location.search;

    } // End handleHistory

    static triggerEvent(type = DART_ROUTE_DISPATCHED) {
        const options = {
            detail: DartRouter.history.location
        };

        if (DART_ROUTE_INITIALIZED) {
            options.cancelable = true;
        }

        const historyEvent = new CustomEvent(type, options);
        return document.dispatchEvent(historyEvent);
    } // End triggerEvent

    static redirect(path, area = 'mainArea', preserveQuery = undefined) {
        if (!DartRouter.triggerEvent(DART_ROUTE_INITIALIZED)) {
            return;
        }

        // const area = $_this.data("area");
        // const preserveQuery = $_this.data('preservequery');
        const urlObj = Core.parseUrlComprehensive(path);

        const splittedPath = path.split('?');
        if (splittedPath.length == 2) {
            path = splittedPath[0]
        }

        if (DartRouter.history) {
            const params = {
                pathname: path,
                state: {"url": urlObj.url, "area": area, "semantic": urlObj.url}
            };

            if (preserveQuery !== undefined) {
                params.search = DartRouter.history.location.search;
            } else {
                if (urlObj.query) {
                    params.search = '?' + qs.stringify(urlObj.query, { encode: false })
                }
            }

            DartRouter.history.push(params);
        } else {
            window.location = path;
            return false;
        }
        return true;
    } // End redirect

    static routeBlocker(blockIt, message = false) {
        if (blockIt === true) {
            if (window.onbeforeunload === null) {
                window.onbeforeunload = () => (true);
            }
            if (DartRouter.history && !DartRouter.unblock) {

                if (message === false) {
                    message = Translate('ARE_YOU_SURE_YOU_WANT_TO_LEAVE_THIS_PAGE') + '?';
                }

                DartRouter.unblock = DartRouter.history.block(message);
            }
        } else {
            if (window.onbeforeunload !== null) {
                window.onbeforeunload = null;
            }
            if (DartRouter.unblock) {
                DartRouter.unblock();
            }
        }
    } // End routeBlocker

} // End DartRouter

export const routeBlocker = DartRouter.routeBlocker;
export const redirect = DartRouter.redirect;
