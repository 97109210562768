/**
 * @author: Håvard Melvin Asperheim Hjelvik
 * @date: 30/10/2017
 */

import {each, debounce} from 'lodash';
import shortid  from 'shortid'

const INITIALIZER = 'INITIALIZER';
const EVENT = 'EVENT';

class DartListener {

    static init() {
        DartListener.observer = new MutationObserver(DartListener.trigger);

        if (!DartListener.listeners) {
            DartListener.listeners = [];
        }

        DartListener.observer.observe(document.body, {
            childList: true,
            subtree: true
        });
    } // End init

    static handleObserve(mutations = null) {
        const listeners = DartListener.listeners;

        each(listeners, listener => {
            let elements = [];

            if (listener.selector === null) {
                console.warn('DartListener: No selector specified for listener', listener);
                return
            }

            if (typeof listener.selector === 'object') {
                elements = [...listener.selector];
            } else {
                const found = $(listener.selector);
                elements = [...found];
            }

            each(elements, (element) => {

                switch (listener.type) {
                    case EVENT:

                        DartListener.commitListener(element, listener);

                        break;
                    case INITIALIZER:
                        let initObj = element.DartInitialized || {};
                        if (!initObj[listener.id]) {
                            initObj[listener.id] = true;
                            element.DartInitialized = initObj;
                            // As far as I know dartId is never used (Melvin).
                            // element.dartId = listener.id;
                            DartListener.commitInitializer(element, listener);
                        }
                        break;
                    default:
                        console.warn('DartListener type does not exist');
                }
            });
        });
    } // End handleObserve

    static addEvent(selector, event, callback) {
        const newObj = {
            id: shortid.generate(),
            selector,
            event,
            callback,
            type: EVENT
        };

        if (!DartListener.listeners) {
            DartListener.listeners = [];
        }
        const currentListeners = DartListener.listeners;

        DartListener.listeners = [ ...currentListeners, newObj ];
        DartListener.trigger();
    } // End addEvent

    static initDOM(selector, callback) {
        const newObj = {
            id: shortid.generate(),
            selector,
            callback,
            type: INITIALIZER
        };

        if (!DartListener.listeners) {
            DartListener.listeners = [];
        }
        const currentListeners = DartListener.listeners;

        DartListener.listeners = [ ...currentListeners, newObj ];
        DartListener.trigger();
    } // End initDOM

    static commitListener(element, listener) {

        const mainEvent = listener.event;
        const evId = listener.id;
        const events = mainEvent.toString().split(" ");

        // As far as I know dartId is never used (Melvin).
        // element.dartId = listener.id;
        if (!element.DartListenersReady) {
            element.DartListenersReady = {};
        }

        const $element = $(element);

        if (Array.isArray(events)) {
            if (!element.DartListenersReady[evId]) {
                each(events, event => {
                    $element.on(event, listener.callback);
                });

                const readyObj = element.DartListenersReady;
                readyObj[evId] = true;
                element.DartListenersReady = readyObj;
            }
        } else {
            if (!element.DartListenersReady[evId]) {
                const readyObj = element.ready;
                readyObj[evId] = true;
                $element.on(mainEvent, listener.callback);
                element.DartListenersReady = readyObj;
            }
        }

    } // End commitListener

    static commitInitializer(element, listener) {
        listener.callback(element);
    } // End commitInitializer

    static trigger(mutations = null) {
        DartListener.handleObserve(mutations);
    } // End trigger


} // End DartListener

export default DartListener;

/**
 *
 * initDOM example:
 *
 * import { initDOM } from 'DartListener';
 *
 * initDOM('.datepicker', obj => {
 *      $(obj).datepicker();
 *      ...
 * });
 *
 */
export const initDOM = DartListener.initDOM;

/**
 *
 * addEvent example:
 *
 * import { addEvent } from 'DartListener';
 *
 * addEvent('.evtClick', 'click', e => {
 *      e.preventDefault();
 *      ...
 * });
 *
 */
export const addEvent = DartListener.addEvent;
